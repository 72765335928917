import {
  Button,
  Grid,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useModalContext,
} from '@chakra-ui/react'

import { usePanelImagesLayoutContext } from '@/components/form/ImagesLayout/PanelImagesLayoutContext'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export type PanelImageSelectModalProps = {
  id: string
}

export const PanelImageSelectModal = ({ id }: PanelImageSelectModalProps) => {
  const { onClose } = useModalContext()
  const { panelImagesLayout, setPanelImagesLayout } =
    usePanelImagesLayoutContext()
  const panelImage = panelImagesLayout.find((image) => image.id === id)
  const [images = []] = useWatchMiraieFields({ name: ['images'] })
  const selectableImages = images.filter(
    ({ img_data_url }) =>
      img_data_url &&
      !panelImagesLayout.find(
        ({ id: imageId, imageUrl }) =>
          imageId !== id && imageUrl === img_data_url,
      ),
  )
  const replaceImage = (imageUrl: string) => {
    setPanelImagesLayout((layout) =>
      layout.map((image) => (image.id === id ? { ...image, imageUrl } : image)),
    )
    onClose()
  }
  const removeImage = () => {
    setPanelImagesLayout((layout) =>
      layout.map((image) =>
        image.id === id ? { ...image, imageUrl: undefined } : image,
      ),
    )
    onClose()
  }

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader>画像を選択</ModalHeader>
      <ModalBody>
        {selectableImages.length ? (
          <Grid templateColumns="repeat(4, 1fr)" gap={2}>
            {selectableImages.map(
              ({ img_data_url }) =>
                img_data_url && (
                  <Grid
                    key={img_data_url}
                    templateRows="1fr auto"
                    objectFit="cover"
                    gap={2}
                    placeItems="center"
                  >
                    <Image src={img_data_url} />
                    <Button
                      size="sm"
                      onClick={
                        img_data_url === panelImage?.imageUrl
                          ? removeImage
                          : () => replaceImage(img_data_url)
                      }
                    >
                      {img_data_url === panelImage?.imageUrl
                        ? '選択を解除する'
                        : 'この画像に差し替える'}
                    </Button>
                  </Grid>
                ),
            )}
          </Grid>
        ) : (
          '差し替え可能な画像がありません'
        )}
      </ModalBody>
      <ModalFooter />
    </ModalContent>
  )
}
