import { Image } from '@chakra-ui/react'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable'
import { useState } from 'react'

import { PanelImage } from '@/components/form/ImagesLayout/PanelImage'
import { PanelImageSortable } from '@/components/form/ImagesLayout/PanelImageSortable'
import { usePanelImagesLayoutContext } from '@/components/form/ImagesLayout/PanelImagesLayoutContext'
import { PanelImagesLayoutGrid } from '@/components/form/ImagesLayout/PanelImagesLayoutGrid'

export const PanelImagesLayout = () => {
  const { panelImagesLayout, setPanelImagesLayout } =
    usePanelImagesLayoutContext()
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  )
  const [draggingItem, setDraggingItem] = useState<{
    id: string
    imageUrl: string | undefined
  }>()
  const onDragStart = ({ active }: DragStartEvent) => {
    setDraggingItem(panelImagesLayout.find(({ id }) => id === active.id))
  }
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setPanelImagesLayout((layout) => {
        const oldIndex = layout.findIndex(({ id }) => id === active.id)
        const newIndex = layout.findIndex(({ id }) => id === over?.id)
        return arrayMove(layout, oldIndex, newIndex)
      })
    }
    setDraggingItem(undefined)
  }
  const onDragCancel = () => setDraggingItem(undefined)

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <PanelImagesLayoutGrid>
        <SortableContext
          id="A"
          items={panelImagesLayout}
          strategy={rectSortingStrategy}
        >
          {panelImagesLayout.map(({ imageUrl, id }) => (
            <PanelImageSortable key={id} id={id}>
              {imageUrl ? <Image src={imageUrl} /> : id}
            </PanelImageSortable>
          ))}
        </SortableContext>
      </PanelImagesLayoutGrid>
      <DragOverlay adjustScale>
        {draggingItem && (
          <PanelImage
            size={panelImagesLayout.indexOf(draggingItem) ? 'small' : 'large'}
            cursor="grabbing"
          >
            {draggingItem.imageUrl ? (
              <Image src={draggingItem.imageUrl} />
            ) : (
              draggingItem.id
            )}
          </PanelImage>
        )}
      </DragOverlay>
    </DndContext>
  )
}
