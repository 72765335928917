import { Center, CenterProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

export type PanelImageProps = CenterProps & {
  size?: 'large' | 'small'
}

export const PanelImage = forwardRef<HTMLDivElement, PanelImageProps>(
  function PanelImage({ size, ...props }, ref) {
    const baseSize = 128
    const scale = {
      large: 2,
      small: 1,
    }
    const boxSize = size ? baseSize * scale[size] + 'px' : null

    return (
      <Center
        rounded="sm"
        border="1px solid"
        borderColor="gray.500"
        objectFit="cover"
        p="2"
        bgColor="white"
        {...(boxSize && { boxSize })}
        {...props}
        ref={ref}
      />
    )
  },
)
